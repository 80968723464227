<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Notes of Interest</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.noteOfInterestDialog.open()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Note</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="notesOfInterestTableHeaders"
              :items="property.notes_of_interest"
              show-expand
              no-data-text="No Notes of Interest found"
            >
              <template v-slot:item.enquiry="{ item }">
                <router-link
                  :to="{
                    name: 'module-melrose-customers-individual',
                    params: { customerId: item.enquiry.customer.id },
                  }"
                  >{{ item.enquiry.customer.full_name }}</router-link
                >
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.noteOfInterestDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      @click="$refs.deleteNoteOfInterestDialog.openDelete(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pt-4 pb-4">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>Notes</th>
                        <td v-html="item.notes" class="pt-4 pb-4"></td>
                      </tr>
                      <tr v-if="item.solicitor">
                        <th>Solicitor</th>
                        <td class="pt-4 pb-4">
                          <div>{{ item.solicitor.name }}</div>
                          <div>
                            <strong>Phone:</strong> {{ item.solicitor.phone }}
                          </div>
                          <div>
                            <strong>Email:</strong> {{ item.solicitor.email }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <note-of-interest-dialog
      ref="noteOfInterestDialog"
      :editingFromProperty="true"
    />

    <delete-note-of-interest-dialog ref="deleteNoteOfInterestDialog" />
  </div>
</template>

<script>
import NoteOfInterestDialog from "./notes-of-interest/components/NoteOfInterestDialog.vue";
import DeleteNoteOfInterestDialog from "./notes-of-interest/components/DeleteNoteOfInterestDialog.vue";

export default {
  components: {
    NoteOfInterestDialog,
    DeleteNoteOfInterestDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          to: { name: "module-melrose-properties-all" },
          exact: true,
        },
      ],
      notesOfInterestTableHeaders: [
        { text: "Date", value: "formatted_dates.date" },
        { text: "Customer", value: "enquiry" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },

  computed: {
    property() {
      return this.$store.getters["melrose/propertiesStore/get"];
    },
  },
};
</script>
